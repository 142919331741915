import Send from '@/api/send'
export default {
    // 알림수신관리자 검색
    getAlrimAdminSearch(data, calcPage){
        return Send({
            "method": "GET",
            "url": "api/admin/v1/member/admin/list?keyword=" + data.keyword +
            "&permissionType=" + data.permissionType +
            "&page=" + calcPage +
            "&size=" + data.size +
            "&orderType=" + data.orderType
        });
    },
    // 알림수신관리자 추가
    postAlrimAdminAdd( data ){
        return Send({
            "method": "POST",
            "url": '/api/admin/v1/member/admin/add',
            "data": data
        });
    },
    // 알림수신관리자 삭제
    postAlrimAdminDelete( userNo ){
        return Send({
            "method": "POST",
            "url": "/api/admin/v1/member/admin/delete/" + userNo
        });
    },
    getPermission(){
        return Send({
            "method": "GET",
            "url": `/api/admin/v1/member/admin/permission/code`
        });
    },
    setPermission(userNo, data){
        return Send({
            "method": "POST",
            "url": `api/admin/v1/member/admin/permission/${userNo}`,
            "data": data
        });
    },
    setPasswordChange(pwd, newPwd){
        return Send({
            "method": "POST",
            "url": "api/admin/v1/me/password",
            "data": {
                "pwd": pwd,
                "newPwd": newPwd
            }
        });
    }    
}