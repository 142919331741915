import Send from '@/api/send'

export default {
  // 발주 검색
  getOrderList(data, calcPage) {
    return Send({
      method: 'get',
      url:
        'api/admin/v1/order/list/search?searchStDate=' +
        data.searchStDate +
        '&searchEndDate=' +
        data.searchEndDate +
        '&companyNo=' +
        data.companyNo +
        '&buyerUserNo=' +
        data.buyerUserNo +
        '&orderStatus=' +
        data.orderStatus +
        '&orderPaymentStatus=' +
        (data.orderPaymentStatus?data.orderPaymentStatus:'') +
        '&orderPrintText=' +
        data.orderPrintText +
        '&page=' +
        calcPage +
        '&size=' +
        data.size +
        '&orderType=' +
        data.orderType,
    })
  },

  // 발주목록 주문상세보기
  getOrderListDetail(data) {
    return Send({
      method: 'get',
      url: 'api/admin/v1/order/view/detail2/' + data.orderNo,
    })
  },

  // 발주목록 주문상세 주류판매계산서
  getInvoice(id) {
    return Send({
      method: 'get',
      url: `/api/admin/v1/order/view/detail/${id}/invoice`,
    })
  },

  // 발주목록 주문상세 주류판매계산서 엑셀
  getInvoiceXlsx(id) {
    return Send({
      method: 'get',
      url: `/api/admin/v1/order/view/detail/${id}/invoice/xlsx`,
      responseType: 'blob',
    })
  },
}
