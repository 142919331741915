<template>
<CBreadcrumb class="d-md-down-none me-auto mb-0">
    <CBreadcrumbItem :key="item" :href="item.active ? '' : item.path"
        :active="item.active" v-for="item in breadcrumbs">{{ item.name }}</CBreadcrumbItem>
</CBreadcrumb>
</template>
<script setup>
import {onMounted, ref} from "vue";
import router from "@/router";
const breadcrumbs = ref();
const getBreadcrumbs = ()=>{
    return router.currentRoute.value.matched.map((route)=>{
        return {
            "active" : route.path === router.currentRoute.value.fullPath,
            "name" : route.name,
            "path" : `${router.options.history.base}${route.path}`,
        }
    });
}
router.afterEach(()=>{
    breadcrumbs.value = getBreadcrumbs();
});
onMounted(()=>{
    breadcrumbs.value = getBreadcrumbs();
});
</script>
