import Send from '@/api/send'
import router from '@/router'

export default {
    login(data) {
        return Send({
            method: 'post',
            url: '/api/admin/v1/user/login',
            data: data,
        })
    },
    
    logout(){
        alert('로그아웃 되었습니다.')
        router.push('/login')
        return Send({
            method: 'get',
            url: '/api/admin/v1/user/logout',
        })
    },

    loginMFA_OTP(formData){ //사용않함
        return Send({
            method: 'post',
            url: '/api/admin/v1/user/login/mfa/otp',
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            },
            data: formData

        })
    }
}