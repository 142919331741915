import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createPinia } from 'pinia'

import Datepicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import 'vue-datepicker-next/locale/ko'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import VPagination from '@hennge/vue3-pagination'
import '../node_modules/@hennge/vue3-pagination/dist/vue3-pagination.css'
import '@/styles/common.css'
import dayjs from 'dayjs'

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(store)
app.use(CoreuiVue)
app.use(VPagination)
app.use( CkeditorPlugin );

app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('VPagination', VPagination)
app.component('Datepicker', Datepicker)
app.component("Multiselect", Multiselect)

app.mount('#app')

// Global
app.config.globalProperties.yearAgo = dayjs()
  .subtract(1, 'year')
  .format('YYYY-MM-DD')
app.config.globalProperties.monthAgo = dayjs()
  .subtract(1, 'month')
  .format('YYYY-MM-DD')
app.config.globalProperties.yesterday = dayjs()
  .add(-1, 'day')
  .format('YYYY-MM-DD')
app.config.globalProperties.today = dayjs().format('YYYY-MM-DD')
