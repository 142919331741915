export default [
	{
		component: 'CNavTitle',
		name: '쓰리랩스 관리자 페이지',
	},
	{
		component: 'CNavGroup',
		name: '회원관리',
		to: '회원관리',
		items: [
			{
				component: 'CNavItem',
				name: '점주 회원정보 및 관리',
				to: '/account/seller',
			},
			{
				component: 'CNavItem',
				name: '수입사 회원정보 및 관리',
				to: '/account/importer',
			},
			{
				component: 'CNavItem',
				name: '시스템 관리자 관리',
				to: '/account/admin',
			},
			{
				component: 'CNavItem',
				name: '계좌예금주명조회',
				to: '/account/findbank',
			},
		],
	},
	{
		component: 'CNavItem',
		name: '채팅관리',
		to: '/manage-chat',
	},
	{
		component: 'CNavGroup',
		name: '광고관리',
		to: '광고관리',
		items: [
			{
				component: 'CNavItem',
				name: '팝업 광고',
				to: '/manage-popup',
			},
			{
				component: 'CNavItem',
				name: '헤더 관리',
				to: '/manage-ad',
			},
			{
				component: 'CNavItem',
				name: '이벤트 관리',
				to: '/manage-event',
			}
		],
	},
	{
		component: 'CNavItem',
		name: '테마관리',
		to: '/manage-theme',
	},
	{
		component: 'CNavItem',
		name: '발주목록 관리',
		to: '/manage-order',
	},
	{
		component: 'CNavItem',
		name: '적립금 관리',
		to: '/manage-point',
	},
	{
		component: 'CNavGroup',
		name: '상품목록 관리',
		to: '상품목록 관리',
		items: [
			{
				component: 'CNavItem',
				name: '상품목록 관리',
				to: '/manage-goods',
			},
			{
				component: 'CNavItem',
				name: '상품등록(파일)',
				to: '/manage-add-goods',
			},
			{
				component: 'CNavItem',
				name: '공급가 변경 승인 관리',
				to: '/manage-supply-price',
			}
		],
	},
	{
		component: 'CNavItem',
		name: '알림관리',
		to: '/manage-notice',
	},
	{
		component: 'CNavItem',
		name: '수입사 정산 관리',
		to: '/manage-settlement',
	},
	{
		component: 'CNavItem',
		name: '수입사 승인관리',
		to: '/manage-importer',
	},
	{
		component: 'CNavGroup',
		name: '랭킹',
		to: '/ranking',
		items: [
			{
				component: 'CNavItem',
				name: '점주 유저 랭킹',
				to: '/ranking/seller',
			},
			{
				component: 'CNavItem',
				name: '수입사 랭킹',
				to: '/ranking/importer',
			},
			{
				component: 'CNavItem',
				name: '와인 랭킹',
				to: '/ranking/wine',
			},
		],
	},
	{
		component: 'CNavGroup',
		name: '로그관리',
		to: '/logs',
		items: [
			{
				component: 'CNavItem',
				name: '알림톡 관리',
				to: '/logs/common-sms-histories',
			},
		],
	},
	{
		component: 'CNavGroup',
		name: '시스템관리',
		to: 'system',
		items: [
			{
				component: 'CNavItem',
				name: '무이자할부 관리',
				to: '/system/card_interest_free'
			},
			{
				component: 'CNavItem',
				name: '시스템 알림',
				to: '/system/notification'
			},
		],
	},
]
