import axios from 'axios'
import router from "@/router";
// import env from '@/environment'

axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: 'http://127.0.0.1:8888',
  //baseURL: 'http://threelabs-api.onoffmix.xyz:11080/',
  //baseURL: 'https://threelabs-api.onoffmix.xyz:11443/',
  //baseURL: 'http://test-marketbang-462608889.ap-northeast-2.elb.amazonaws.com',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

instance.interceptors.request.use(
   ( config )=>{
     config.baseURL = (()=>{
      let return_str = "/";
      if (window.location.origin.indexOf('localhost') > -1 || window.location.origin.indexOf('127.0.0.1') > -1) {
        return config.baseURL
      } else {
        if( window.location.origin.indexOf("admin.") > -1 ){
           return_str = window.location.origin.replace("admin.", "api.");
        }
      }
      return return_str;
     })();
     return config;
   },
  (config) => {
    return config
  },

  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    return response
  },

  (error) => {
    if(error.response.status === 401 && error.response.data.code === 401) {
      //alert('로그인이 필요합니다.');
      router.push('/login')
    }
    return Promise.reject(error)
  },
)

export default instance
